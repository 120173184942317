<template>
  <div class="container">
    <p class="amount">{{ formatNumber(payment.fiat_amount) }} ₽</p>
    <h1 class="title">Не удается создать пополнение на эту сумму</h1>
    <p class="description">
      Измените сумму платежа, чтобы пополнить счет без ожидания:
    </p>
    <div v-if="isLoading" class="loader methods"></div>
    <div class="gaps" v-else>
      <button
        class="gap"
        :class="{ selected: selectedGap === gap }"
        v-for="gap in gaps"
        :key="gap"
        :disabled="isCreating"
        @click="
          selectedGap = gap;
          isError = false;
        "
      >
        {{ formatNumber(Number(payment.fiat_amount) + Number(gap)) }} ₽
      </button>
    </div>
    <p v-if="isCreatingGapError" class="error">
      Эта сумма не доступна, пожалуйста, выберите другую
    </p>
    <CustomButton
      component="button"
      class="btn-change"
      :label="`Пополнить ${formatNumber(
        Number(payment.fiat_amount) + Number(selectedGap)
      )} ₽`"
      @click="handleCrateTransaction"
      :disabled="!selectedGap || isCreating"
    />
  </div>
</template>

<script>
import formatNumber from '@/assets/utils/formatNumber';
import CustomButton from './CustomButton.vue';
import { createTransaction, getPaymentMethodsById } from '@/lib/api';
import { tracker } from '@/tracker';

export default {
  name: 'ErrorChangeAmount',
  data() {
    const arr = window.location.pathname.split('/');

    return {
      gaps: [],
      isLoading: false,
      isErrorFetch: false,
      selectedGap: 0,
      uuid: arr[arr.length - 1],
      isCreating: false,
      isCreatingGapError: false,
    };
  },
  props: {
    payment: Object,
    selectedBank: String,
    loadPaymentData: Function,
  },
  components: {
    CustomButton,
  },
  mounted() {
    if (this.uuid) {
      this.getPaymentMethodsById();
    }
  },
  methods: {
    formatNumber: formatNumber,
    async handleCrateTransaction() {
      try {
        this.isCreating = true;
        this.isCreatingGapError = false;

        const { data } = await createTransaction({
          uuid: this.uuid,
          payment_method: 'click',
          payeer_bank_name: this.selectedBank,
          fiat_amount:
            Number(this.payment.fiat_amount) + Number(this.selectedGap),
        });
        this.isCreating = false;
        this.$emit('loadPaymentData', data?.data);
      } catch (error) {
        tracker.handleError(error, { context: 'change amount error' });
        console.log('error :>> ', error);
        this.isCreatingGapError = true;
        this.isCreating = false;
      }
    },

    async getPaymentMethodsById() {
      try {
        this.isLoading = true;
        this.isErrorFetch = false;

        const { data } = await getPaymentMethodsById({ uuid: this.uuid });

        const clickGaps = data.data?.find(
          (gap) => gap.payment_method === 'click'
        );

        if (clickGaps && clickGaps.fiat_amount_gaps) {
          this.gaps = clickGaps.fiat_amount_gaps;
        }

        this.isLoading = false;
      } catch (error) {
        this.isErrorFetch = true;
        this.isLoading = false;
        console.log('error :>> ', error);
      }
    },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
  gap: unset;
}

.amount {
  margin-bottom: 10px;
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 700;
  color: #b8b7b5;
  border-radius: 40px;
  background-color: #f0f0f0;
}

.title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
}

.description {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: #676560;
}

.gaps {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.gap {
  padding: 15px 30px;
  border-radius: 12px;
  background-color: #f9f9f9;
  font-size: 18px;
  font-weight: 700;
  color: #676560;
  outline: 1.5px solid;
  outline-color: transparent;
  transition: all 0.2s;
}

.gap:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.gap.selected {
  outline-color: #ff8256;
  background-color: rgba(255, 130, 86, 0.15);
  color: #ff8256;
}

.error {
  margin-bottom: 20px;
  color: var(--error-color);
}

.btn-change {
  background-color: #ff8256;
  color: #ffff;
  font-variant-numeric: tabular-nums;
}

.btn-change:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loader.methods {
  position: static;
  margin-bottom: 20px;
}
</style>
