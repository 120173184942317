import axios from 'axios';
import { getFp } from '../../public/setd';

const host = process.env.VUE_APP_API_HOST;

export const createTransaction = async (data) => {
  return axios.post(`${host}/payer/v1/deposits`, data);
};

export const markPaidTransaction = async ({ uuid }) => {
  return axios.put(`${host}/payer/v1/deposits/${uuid}/mark_paid`);
};

export const cancelTransaction = async ({ uuid }) => {
  return axios.put(`${host}/payer/v1/deposits/${uuid}/cancel`);
};

export const fetchTransaction = async ({ uuid }) => {
  const fp = await getFp();

  return axios.get(`${host}/payer/v1/deposits/${uuid}`, {
    headers: { fp, Authorization: fp },
  });
};

export const getPaymentMethodsById = async ({ uuid }) => {
  return axios.get(`${host}/payer/v1/payment_methods/${uuid}`);
};
