<template>
  <div id="main" v-if="!loadingInitial">
    <div class="header">
      <img src="./assets/img/logo.svg" class="logo" alt="payeveryday.net" />
      <PaymentTimer
        v-if="
          endTime &&
          ['waiting_payment', 'processing', 'paid'].includes(this.payment.state)
        "
        :endTime="endTime"
      />
    </div>
    <transition :name="transitionName" mode="out-in">
      <div :key="currentScreen" class="screen">
        <component
          @openModal="openModal"
          @closeModal="closeModal"
          @markCancel="markCancel"
          @markPaid="markPaid"
          @createTransaction="createTransaction"
          @loadPaymentData="loadPaymentData"
          v-if="payment && !loadingInitial"
          :is="currentScreen"
          :payment="payment"
          :isCreating="isCreating"
          :isCreatingError="isCreatingError"
        />

        <!-- Элементы, которые должны анимироваться вместе с переходом -->
        <div v-if="currentScreen === 'PaymentScreen'" class="shield">
          <img src="./assets/img/shield.svg" /> Транзакция защищена
        </div>
      </div>
    </transition>
    <!-- Показ прелоадера во время загрузки -->
    <div v-if="isLoading" class="loader"></div>
  </div>

  <div v-else>
    <!-- Прелоадер во время начальной загрузки -->
    <div class="loader"></div>
  </div>
  <Error v-if="isError" />
  <PaymentFooter />
  <ModalWindow v-if="isModalOpened">
    <component :is="modalName" @closeModal="closeModal"></component>
  </ModalWindow>
</template>

<script>
import SelectBank from './components/SelectBank.vue';
import PaymentScreen from './components/PaymentScreen.vue';
import PaymentResult from './components/PaymentResult.vue';
import Error from './components/Error.vue';

import PaymentTimer from './components/PaymentTimer.vue';
import PaymentFooter from './components/PaymentFooter.vue';
import ErrorChangeAmount from './components/ErrorChangeAmount.vue';
import ModalWindow from './components/ModalWindow.vue';
import LookingForPayment from './components/LookingForPayment.vue';

import VSpoiler from 'v-spoiler';
import 'v-spoiler/dist/v-spoiler.css';
import './assets/styles/global.css';

import {
  fetchTransaction,
  createTransaction,
  markPaidTransaction,
  cancelTransaction,
} from '@/lib/api';
import { tracker } from '@/tracker';
import { ERROR_MESSAGE_SERVICE_NOT_AVAILABLE } from '@/lib/constants';

export default {
  name: 'App',
  data() {
    const arr = window.location.pathname.split('/');

    return {
      currentScreen: 'SelectBank',
      selectedBank: '',
      payment: null,
      isLoading: false,
      isError: false,
      loadingInitial: true,
      isCreating: false,
      endTime: null,
      modalName: null,
      isModalOpened: false,
      isStartPaid: false,
      isCreatingError: false,
      uuid: arr[arr.length - 1],
      firstReload: true,
      intervalId: null,
    };
  },
  components: {
    SelectBank,
    PaymentScreen,
    ErrorChangeAmount,
    PaymentResult,
    PaymentTimer,
    VSpoiler,
    PaymentFooter,
    ModalWindow,
    Error,
    LookingForPayment,
  },
  mounted() {
    if (this.uuid) {
      tracker.start();
      tracker.setUserID(this.uuid);
      tracker.setMetadata('merchant_name', 'winway');
      tracker.setMetadata('payment_method', 'click');

      this.loadPaymentData();
      this.intervalId = setInterval(() => {
        this.loadPaymentData();
      }, 3000);
      document.title = 'PayEveryDay.net';
    }
  },
  unmounted() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    markCancel() {
      try {
        cancelTransaction({
          uuid: this.uuid,
        });
        this.loadPaymentData();
      } catch (error) {
        console.log('error :>> ', error);
      }
    },

    async markPaid() {
      try {
        this.isStartPaid = true;

        await markPaidTransaction({
          uuid: this.uuid,
        });

        this.isStartPaid = false;

        this.loadPaymentData();
      } catch (error) {
        this.isStartPaid = false;
      }
    },
    async createTransaction(params) {
      try {
        this.isCreating = true;
        this.isCreatingError = false;

        if (params.payeer_bank_name) {
          this.selectedBank = params.payeer_bank_name;
        }

        await createTransaction({
          ...params,
          uuid: this.uuid,
          payment_method: 'click',
        });
        this.isCreating = false;
        this.loadPaymentData();
      } catch (error) {
        if (
          error.response?.data?.message === ERROR_MESSAGE_SERVICE_NOT_AVAILABLE
        ) {
          this.currentScreen = 'ErrorChangeAmount';
        }

        this.isCreatingError = true;
        this.isCreating = false;
      }
    },
    async loadPaymentData(initialPayment = null) {
      try {
        if (initialPayment) {
          this.payment = initialPayment;
        }

        const { data } = await fetchTransaction({
          uuid: this.uuid,
        });

        this.loadingInitial = false;
        this.payment = data.data;

        if (
          !this.payment.source_transaction?.payeer_bank_name &&
          this.currentScreen === 'ErrorChangeAmount'
        ) {
          return;
        }

        if (!this.payment.source_transaction?.payeer_bank_name) {
          this.currentScreen = 'SelectBank';
        } else {
          if (
            ['waiting_payment', 'processing', 'paid'].includes(
              this.payment.state
            )
          ) {
            this.currentScreen = 'PaymentScreen';
            this.endTime = this.payment.source_transaction.close_at;
            if (this.payment.state == 'paid') {
              if (this.firstReload) {
                this.firstReload = false;
                this.openModal('LookingForPayment');
              }
            }
          } else {
            this.currentScreen = 'PaymentResult';
          }
        }
      } catch (error) {
        this.loadingInitial = false;
        this.isError = true;
      }
    },
    openModal(name) {
      this.modalName = name;
      this.isModalOpened = true;
      document.body.classList.add('no-scroll');
    },
    closeModal() {
      this.isModalOpened = false;
      this.modalName = null;
      document.body.classList.remove('no-scroll');
    },
  },
  computed: {
    transitionName() {
      return this.isLoading ? '' : 'fade';
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.greeting {
  font-size: 24px;
  text-align: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
