<template>
  <div class="container">
    <!-- <div class="badge badge_success">Пополнение на {{ formatted_amount }} ₽</div> -->
    <div>
      <h1>Выберите банк</h1>
      <p class="mt-15">
        После выбора откроется ваше банковское приложение, где&nbsp;произойдет
        оплата
      </p>
    </div>

    <div class="bank-list">
      <div
        v-for="bank in banks"
        :key="bank.name"
        class="bank-item"
        @click="selectBank(bank)"
      >
        <div class="bank-logo">
          <img :src="bank.logo" />
        </div>
        <h2 class="bank-title">{{ bank.title }}</h2>
        <img src="../assets/img/arrow-right.svg" />
      </div>
    </div>
    <div style="display: none !important">
      <CustomButton
        component="button"
        label="У меня другой банк"
        :disabled="isCreating"
        @click="selectBank(otherBank)"
        class="gray small"
      />
    </div>
    <p v-if="isCreatingError" class="error">
      Не удалось создать платеж, попробуйте позже
    </p>
  </div>
</template>

<script>
import '../assets/styles/SelectBank.css';
import CustomButton from './CustomButton.vue';

export default {
  name: 'SelectBank',
  components: {
    CustomButton,
  },
  props: {
    payment: Object,
    isCreating: Boolean,
    isCreatingError: Boolean,
  },
  data() {
    return {
      banks: [
        {
          name: 'sberbank',
          logo: require('../assets/img/banks/sberbank.svg'),
          title: 'Сбербанк',
          header: 'Оплата со Сбербанка',
        },
        {
          name: 'tinkoff',
          logo: require('../assets/img/banks/tbank.svg'),
          title: 'Т-Банк',
          header: 'Оплата с Т-Банка',
        },
      ],
      otherBank: {
        name: 'other',
        logo: null,
        title: 'Другой банк',
        header: 'Оплатите со своего банка',
      },
    };
  },
  methods: {
    selectBank({ name }) {
      if (!this.isCreating) {
        this.$emit('createTransaction', { payeer_bank_name: name });
      }
    },
  },
};
</script>

<style scoped>
  .error {
    color: var(--error-color);
  }
</style>
