import OpenReplay from '@openreplay/tracker';

export const tracker = new OpenReplay({
  projectKey: 'nqAjtRFOdf5h0SpTbuKX',
  ingestPoint: 'https://finboard.cc/ingest',
  capturePerformance: true,
  // __DISABLE_SECURE_MODE: true,
  network: {
    capturePayload: true,
    captureInIframes: true,
    failuresOnly: false,
    ignoreHeaders: false,
    sessionTokenHeader: '',
  },
});
